import { defineStore } from "pinia";
import useYaSlot from "@magnit/core/src/composables/useYaSlot";

export enum IYaSlotApiStatus {
  INIT = "init",
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}

export const useSlotsStore = defineStore("slots", () => {
  const slotPopupWasDisplayed = ref(false);
  const slotStatus = ref(IYaSlotApiStatus.INIT);
  const { loadSlotApi } = useYaSlot();
  const loadSlotApiInit = async () => {
    slotStatus.value = IYaSlotApiStatus.PENDING;
    const result = await loadSlotApi();
    slotStatus.value = result
      ? IYaSlotApiStatus.SUCCESS
      : IYaSlotApiStatus.ERROR;
  };
  const slotApiLoaded = computed(
    () =>
      slotStatus.value === IYaSlotApiStatus.SUCCESS ||
      slotStatus.value === IYaSlotApiStatus.ERROR,
  );
  return {
    loadSlotApiInit,
    slotApiLoaded,
    slotPopupWasDisplayed,
    slotStatus,
  };
});
